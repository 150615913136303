import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  Heading,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Show,
  Spacer,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsPersonCircle as UserIcon } from "react-icons/bs";
import { FaKey as PasswordIcon } from "react-icons/fa";
import {
  MdDone as CheckIcon,
  MdClose as CloseIcon,
  MdOutlineFileDownload as DownloadIcon,
  MdOpenInNew as ExternalLinkIcon,
  MdMenu as HamburgerIcon,
  MdLogout as LogoutIcon,
  MdTranslate as TranslateIcon,
} from "react-icons/md";
import { TbPlugConnected as ConnectIcon } from "react-icons/tb";
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  ActiveSessionButton,
  ConnectDeviceButton,
  ConnectedDevicesButton,
  SearchButton,
} from ".";
import { useAuthentication } from "../auth";
import { useBranding } from "../branding";
import { portalBackendUrl } from "../config";
import { openConfigurationDialog } from "../features/cloudRenderingSlice";
import { selectOnlineDevices } from "../features/devicesSlice";
import {
  selectSessionServiceConnectionState,
  selectSessionState,
} from "../features/sessionSlice";
import {
  useActiveOrganizationQuery,
  useAppDispatch,
  useAppSelector,
} from "../hooks";
import { Organization } from "../hooks/types";
import { useBaseUrl, useControlPanelUrl } from "../hooks/useControlPanelUrl";
import { supportedLanguages } from "../i18n";
import { SESSION_STATE } from "../session/session-state";
import { isMobile } from "../utils/browser-support";
import { routes } from "../utils/routes";
import { CloudRenderingServiceStatus } from "./CloudRenderingServiceStatus";
import { ConfigurationIcon } from "./icons";
import { InnerMenu } from "./InnerMenu";
import { Logo } from "./Logo";
import { StreamingButton } from "./StreamingButton";

function navigateToOrganization(organization: Organization) {
  const newWindow = window.open(
    `${window.location.protocol}//${organization.domain}:${window.location.port}`,
    "_blank",
  );
  newWindow?.focus();
}

function LanguageIcon({ languageKey }: { languageKey: string }) {
  if (languageKey === "en") {
    return "🇺🇸";
  }

  if (languageKey === "de") {
    return "🇩🇪";
  }

  return languageKey;
}

export function VRHMDActions(props: ButtonProps) {
  const connectedHMDs = useAppSelector(selectOnlineDevices);
  const currentSession = useAppSelector(selectSessionState);
  const connectionState = useAppSelector(selectSessionServiceConnectionState);

  if (connectionState !== "Connected") {
    return null;
  }

  if (!!currentSession.id && currentSession.state < SESSION_STATE.ENDED) {
    return <ActiveSessionButton {...props} />;
  }

  if (!connectedHMDs.length) {
    return <ConnectDeviceButton {...props} />;
  }

  return <ConnectedDevicesButton {...props} />;
}

function Actions(props: StackProps) {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const showHMDActions = useBreakpointValue({
    base: false,
    md: !!user,
  });
  const { data: activeOrganization } = useActiveOrganizationQuery();
  const baseUrl = useBaseUrl();
  const controlPanelUrl = useControlPanelUrl();
  const { pathname } = useLocation();
  const isOnHomePage = useMemo(
    () =>
      [
        routes.application.details,
        routes.application.detailsViaGroup,
        routes.home,
      ].some((path) => matchPath(path, pathname)),
    [pathname],
  );

  const logout = () => {
    navigate(routes.logout);
  };

  const sortedOrganizations = useMemo(() => {
    if (!user) return [];
    return user.organizations.sort((a, b) => a.name.localeCompare(b.name));
  }, [user]);

  const switchOrganization = useCallback((organization: Organization) => {
    navigateToOrganization(organization);
  }, []);

  const languages = useMemo(() => {
    return supportedLanguages.map((languageKey) => (
      <MenuItem
        key={languageKey}
        onClick={() => i18n.changeLanguage(languageKey)}
        icon={
          i18n.resolvedLanguage === languageKey ? (
            <CheckIcon />
          ) : (
            <LanguageIcon languageKey={languageKey} />
          )
        }
        isDisabled={i18n.resolvedLanguage === languageKey}
      >
        {t(`languages.${languageKey}`)}
      </MenuItem>
    ));
  }, [i18n, t]);

  return (
    <>
      <Stack {...props}>
        {user && (
          <>
            {isOnHomePage && (
              <Show above="md">
                <SearchButton />
              </Show>
            )}
            {i18n.languages.length && isMobile && (
              <Box>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="ghost"
                    leftIcon={<TranslateIcon />}
                  >
                    {t("header.language")}
                  </MenuButton>
                  <MenuList width={["100vw", "auto"]}>{languages}</MenuList>
                </Menu>
              </Box>
            )}
            <StreamingButton />
            {user && (
              <Box>
                <Menu preventOverflow flip>
                  <MenuButton
                    as={Button}
                    variant="ghost"
                    leftIcon={<UserIcon />}
                  >
                    {user?.full_name}
                  </MenuButton>
                  <MenuList width={["100vw", "auto"]}>
                    <MenuOptionGroup
                      title="Organization"
                      type="radio"
                      value={activeOrganization?.id.toString()}
                    >
                      <Box maxHeight={40} overflow={"auto"}>
                        {sortedOrganizations.map((org) => (
                          <MenuItemOption
                            key={org.id}
                            value={org.id.toString()}
                            onClick={() => switchOrganization(org)}
                            closeOnSelect={true}
                          >
                            {org.name}
                          </MenuItemOption>
                        ))}
                      </Box>
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuItem
                      icon={<ConnectIcon />}
                      as={RouterLink}
                      to={routes.connectDevice}
                    >
                      {t("clients.connect")}
                    </MenuItem>
                    <MenuItem
                      icon={<DownloadIcon />}
                      as={RouterLink}
                      to={routes.clients.install}
                    >
                      {t("pairing.install_client")}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      icon={<Icon as={ConfigurationIcon} boxSize={3} />}
                      onClick={() => dispatch(openConfigurationDialog())}
                    >
                      {t("details.streaming_preferences")}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      icon={<ExternalLinkIcon />}
                      as="a"
                      href={controlPanelUrl}
                      target="_blank"
                    >
                      {t("menu.control_panel")}
                    </MenuItem>
                    {user?.is_superuser && (
                      <>
                        <MenuItem
                          icon={<ExternalLinkIcon />}
                          as="a"
                          href={portalBackendUrl + "/admin"}
                          target="_blank"
                        >
                          {t("menu.admin_panel")}
                        </MenuItem>
                        <MenuDivider />
                      </>
                    )}
                    <MenuItem
                      icon={<PasswordIcon />}
                      as="a"
                      href={
                        baseUrl +
                        "/auth/change-password/?next=/control-panel/portal-frontend/"
                      }
                    >
                      {t("header.change_password")}
                    </MenuItem>
                    {!isMobile && (
                      <InnerMenu
                        menuButton={{
                          icon: <TranslateIcon />,
                          children: <Text>{t("header.language")}</Text>,
                        }}
                      >
                        {languages}
                      </InnerMenu>
                    )}
                    <MenuDivider />
                    <MenuItem icon={<LogoutIcon />} onClick={logout}>
                      {t("header.logout")}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )}
            <CloudRenderingServiceStatus alignSelf="center" />
            {showHMDActions && <VRHMDActions marginLeft="4" />}
          </>
        )}
      </Stack>
    </>
  );
}

/**
 * Responsive AppBar / NavBar
 * inspired by https://raptis.wtf/blog/create-a-navbar-with-chakra-ui-react/
 */
export const AppBar = (props: FlexProps) => {
  const { user } = useAuthentication();
  const showHMDActions = useBreakpointValue({
    base: !!user,
    md: false,
  });

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const { data: branding } = useBranding();
  const productName = `${branding?.product_name || "Portal"}`;

  return (
    <>
      <Flex
        as="header"
        position={"fixed"}
        width="100%"
        bgColor={"chakra-body-bg"}
        // use emotion's fallback styles to ensure we cover the full screen, see https://emotion.sh/docs/object-styles#fallbacks
        css={isOpen ? { height: ["100vh", "100dvh"] } : undefined}
        zIndex="sticky"
        // required to make up for "viewport-fit=cover" on mobile devices (e.g. iPhones); see https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
        padding="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
        {...props}
      >
        {isOpen && (
          // disable scrolling on page while menu is opened on mobile
          <Global
            styles={{
              body: { height: ["100vh", "100dvh"], overflow: "hidden" },
            }}
          />
        )}
        <Flex
          paddingX={6}
          paddingY={4}
          justifyContent={["space-between"]}
          flexDirection={["column", "column", "row"]}
          width="full"
          alignItems={{ md: "center" }}
        >
          <Flex alignItems={["center"]}>
            <IconButton
              display={["inline-flex", "inline-flex", "none", "none"]}
              aria-label={isOpen ? "Close Menu" : "Open Menu"}
              size="lg"
              mr={4}
              icon={
                isOpen ? (
                  <Icon as={CloseIcon} boxSize={7} />
                ) : (
                  <Icon as={HamburgerIcon} boxSize={7} />
                )
              }
              onClick={toggle}
            />
            <Link
              as={RouterLink}
              _focus={undefined}
              to="/"
              marginRight="6"
              variant={"unstyled"}
            >
              <Logo height={12} />
            </Link>
            <Heading
              as="h2"
              size="md"
              display={["none", "none", "none", "block"]}
            >
              <Link variant={"unstyled"} as={RouterLink} to="/">
                {productName}
              </Link>
            </Heading>

            <Spacer />
            {showHMDActions && <VRHMDActions />}
          </Flex>
          <Box
            flexGrow={isOpen ? [1, 1, null, null] : undefined}
            display={[
              isOpen ? "block" : "none",
              isOpen ? "block" : "none",
              "block",
            ]}
          >
            <Actions
              justify={"flex-end"}
              direction={["column", "column", "row", "row"]}
              alignItems={["center", "center", "flex-start", "flex-start"]}
              pt={[4, 4, 0, 0]}
              pb={[12, 12, 0, 0]}
              height={isOpen ? ["100%", "100%", null, null] : undefined}
            />
          </Box>
        </Flex>
      </Flex>
      {/* Placeholder under the appbar to push content down */}
      {!isOpen && <Box height={"80px"} />}
    </>
  );
};
