import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  closeConfigurationDialog,
  selectCloudRendering,
} from "../features/cloudRenderingSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { CloudRenderingRegionPreferences } from "./CloudRenderingRegionPreferences";
import { StreamingSecurityPreferences } from "./StreamingSecurityPreferences";
import { StreamingTechnologyPreferences } from "./StreamingTechnologyPreferences";
import { useAuthentication } from "../auth";

export function StreamingPreferencesDialog() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const cloudRendering = useAppSelector(selectCloudRendering);
  const { user_permissions } = useAuthentication();

  const onClose = () => {
    dispatch(closeConfigurationDialog());
  };

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={cloudRendering.configurationDialogShown}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("streamingPreferences.dialog.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>{t("streamingPreferences.region_preference")}</Tab>
                <Tab>{t("streamingPreferences.technology")}</Tab>
                {user_permissions.includes("core.remote_rendering.use_dcv") && (
                  <Tab>{t("streamingPreferences.security_preferences")}</Tab>
                )}
              </TabList>

              <TabPanels>
                <TabPanel>
                  <CloudRenderingRegionPreferences />
                </TabPanel>
                {user_permissions.includes("core.remote_rendering.use_dcv") && (
                  <TabPanel>
                    <StreamingTechnologyPreferences />
                  </TabPanel>
                )}
                <TabPanel>
                  <StreamingSecurityPreferences />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => onClose()}>
              {t("streamingPreferences.dialog.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
